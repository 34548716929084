<template>
    <div>
        <page-title heading="VCITA" subheading="VCITA" :icon=icon></page-title>
        <div class="app-main__inner">
        <vcitaInterface></vcitaInterface>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import vcitaInterface from "../../../components/settings/moduleSettings/vcitaInterface.vue";

    export default {
        components: {
            PageTitle,
            vcitaInterface
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>